class Cart {
    constructor(apiConfig, http) {
        const { siteInfo } = apiConfig;
        const { storeId } = siteInfo;
        this.apiConfig = apiConfig;
        this.http = http;
        this.path = `/wcs/resources/store/${storeId}/cart`;
        this.cartServicePath = `/wcs/resources/store/${storeId}/HPCartService`;
    }

    get(params, config) {
        const { orderId, calculate, timestamp = true, ...restParams } = params || {};
        if (calculate) {
            return this.calculate(orderId, restParams, config);
        }
        return this.http(`${this.path}/@self`, {
            params: { ...restParams, ...(timestamp ? { _: Date.now() } : {}) },
            method: 'get',
            ...config,
        }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }

    calculate(orderId, params, config) {
        const data = {
            calculationUsageId: ['-1,-2,-3,-4,-5,-6,-7,-8,-9'],
            continue: ['1'],
            createIfEmpty: ['1'],
            deleteCartCookie: ['true'],
            deleteIfEmpty: ['*'],
            fromOrderId: ['*'],
            orderId: ['69625459967593'],
            page: [''],
            toOrderId: ['.'],
            updatePrices: ['1'],
            URL: ['AjaxOrderItemDisplayView'],
        };

        return this.http
            .post(`${this.path}/${orderId}/calculate?URL=AjaxOrderItemDisplayView`, { data, ...config })
            .then(resp => {
                const { data, status } = resp;
                //attempt to parse response
                if (status === 200 && data && data !== '') {
                    return this.get(params, config);
                } else {
                    console.log(`Error calculating cart: ${status}`);
                    return this.get(params, config);
                    // return Promise.reject({});
                }
            })
            .catch(e => {
                //TODO: Need to check if it's okay to proceed with cart call if calculate call fails
                console.log(`Error calculating cart: ${e}`);
                return this.get(params, config);
            });
    }

    deliveryOptions(params, config) {
        return this.http(`${this.cartServicePath}/delivery-options`, {
            params: { responseFormat: 'json', ...(params || {}) },
            method: 'get',
            ...config,
        }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }

    carepackInfo(params, config) {
        return this.http(`${this.cartServicePath}/fetchHWWithoutCP`, {
            params: { responseFormat: 'json', _: Date.now(), ...(params || {}) },
            method: 'get',
            ...config,
        }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }

    viewConfig(params, config) {
        return this.http(`${this.cartServicePath}/vieweditconfig`, {
            params: { responseFormat: 'json', ...(params || {}) },
            method: 'get',
            ...config,
        }).then(resp => {
            const { data, status } = resp;
            //attempt to parse response
            if (status === 200 && data && data !== '') {
                return data;
            } else {
                return Promise.reject({});
            }
        });
    }
}

export default Cart;
