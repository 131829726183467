import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useThirdPartyTags } from '../hooks/useSiteConfig';
import { Helpers } from '../core/src/helpers';

const EMPTY_OBJECT = {};

const deriveCategoryFromBreadcrumbs = seo => {
    const { breadcrumbs } = seo || {};
    return breadcrumbs.reduce((r, b, idx) => {
        if (idx === 0 && b.label.toLowerCase() === 'home') {
            return r;
        }
        r.push(b.id);
        return r;
    }, []);
};

const getContext = slugInfo => {
    let type = 'OTHER';
    let data;
    try {
        const { components, templateKey, seo } = slugInfo || EMPTY_OBJECT;
        const { productInitial } = components || EMPTY_OBJECT;
        const { sku } = productInitial || EMPTY_OBJECT;
        if (templateKey === 'home') {
            type = 'HOMEPAGE';
        } else if (['cat', 'mlp', 'mdp', 'vwa'].includes(templateKey)) {
            type = 'CATEGORY';
            const categories = deriveCategoryFromBreadcrumbs(seo);
            data = categories && categories.length > 0 ? categories : undefined;
        } else if (!!sku) {
            type = 'PRODUCT';
            data = [sku];
        }
    } catch (e) {}
    return { type, data };
};
//depricated. Cookie set in akamai
const setITPCookie = () => {
    const cookieID = '_dyid';
    const serverCookieID = '_dyid_server';
    const dyIDCookie = Helpers.getCookie(cookieID);
    const dyIDServer = Helpers.getCookie(serverCookieID);
    if (dyIDCookie && !dyIDServer) {
        /* if this is a returning user and the DYID cookie exists */
        Helpers.setCookie(serverCookieID, dyIDCookie, 365); /* expire in 1 year */
    }
};

export default ({ slugInfo, lang = 'en_US' }) => {
    const { type, data } = getContext(slugInfo);
    const { personalizationConsentString = '2:1,4:1', abTestConsentTriggerEnabled = true } = useThirdPartyTags([
        'personalizationConsentString',
        'abTestConsentTriggerEnabled',
    ]);

    return (
        <Helmet>
            <script type="text/javascript">
                {`
                    try{
                        window.DY = window.DY || { };
                        DY.recommendationContext = {type: "${type}"${
                            data && data.length > 0 ? `,data: ${JSON.stringify(data)}` : ''
                        },lng: "${lang}"};
                        DY.userActiveConsent = { accepted: window.localStorage.getItem('userActiveConsent') || false }
                        const setOptIn = function(val){
                            try{
                                DY.userActiveConsent = { accepted: val }
                                DYO.ActiveConsent.updateConsentAcceptedStatus(val)
                                window.localStorage.setItem('userActiveConsent', val)
                                //workaround for DY not updating based on userActiveConsent
                                if(val && ${abTestConsentTriggerEnabled}){
                                    const { data, ...rest } = DY.recommendationContext;
                                    DY.recommendationContext = {...rest, data: (data || []).concat(["after_consent"])}
                                }
                            }catch(e){
                                console.log('Error setting DY conset',e)
                            }
                        }
                        //check for opt in
                        dataLayer.push({
                            event:'pb.onOptInGroups',
                            groups: '${personalizationConsentString}', 
                            callback: function(){setOptIn(true)} 
                          })
                        //check for opt out
                        dataLayer.push({
                            event:'pb.onOptInGroups',
                            groups: '${personalizationConsentString.replace(/\:1/gi, ':0')}', 
                            callback: function(){setOptIn(false)} 
                          })
                    }catch(e){
                        console.log('Error setting DY conset',e)
                    }
                `}
            </script>
        </Helmet>
    );
};
