import { useSelector, useDispatch } from 'react-redux';

import { handleClearRecentlyAdded, fetchCartView } from '../utility/utility-actions';
import { EtrAPI } from '../core/src/etr-api-client';
import EtrAPIClient from '../core/src/etr-wcs-api-client';
import useUserData, { isCallCenter } from './useUserData';
import useAddToCart from './useAddToCart';

/**
 * Returns the redux state for the Cart
 * @name useCartState
 * @returns {cartInfo, cartId, clearRecentlyAdded, getCartItem}
 */
export default function useCartState(options = {}) {
    const cartInfo = useSelector(state => state.cartInfo);
    const dispatch = useDispatch();
    const cartViewIsLoading = !cartInfo?.cartView;
    return {
        cartInfo,
        cartId: cartInfo && cartInfo.id,
        clearRecentlyAdded: () => dispatch(handleClearRecentlyAdded()),
        getCartItem: sku => cartInfo && cartInfo.items && cartInfo.items.find(item => item.pNum === sku),
        setCartView: calculate => dispatch(fetchCartView(calculate)),
        cartViewIsLoading,
    };
}

/***
 * Returns cart actions that will refresh cart view after the action is completed
 */
export const useCartActions = () => {
    const dispatch = useDispatch();
    const orderId = useSelector(state => state.cartInfo?.id);
    const { userData } = useUserData();
    const ccf = isCallCenter(userData);
    const { addToCart } = useAddToCart();
    const commonParams = {
        orderId,
        token: 'NA',
        finalView: 'AjaxOrderItemDisplayView',
        loyaltyAmt: '',
        isLoyaltyEligible: '',
        ccf,
    };
    return {
        setPromoCode: (promotionCode, addPromo) => {
            return EtrAPI.cart
                .setPromoCode({ ...commonParams, promoCode: promotionCode, taskType: addPromo ? 'A' : 'R' })
                .then(resp => {
                    dispatch(fetchCartView(false));
                    return resp;
                });
        },
        setZipCode: zipCode => {
            return EtrAPI.cart.setZipCode({ ...commonParams, zipCode, URL: '/' }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        updateCartItem: (orderItemId, qty) => {
            const { orderId, ccf } = commonParams;
            return EtrAPI.cart.updateCartItem(orderItemId, qty, { orderId, ccf }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        getDeliveryOptions: () => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.deliveryOptions({ orderId }).then(resp => {
                return resp;
            });
        },
        updateShipMethod: shipModeId => {
            const { orderId, ccf } = commonParams;
            return EtrAPI.cart.updateShipMethod({ orderId, shipModeId, ccf }).then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
        viewConfig: itemId => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.viewConfig({ orderId, itemId }).then(resp => {
                return resp;
            });
        },
        getCarePackInfo: () => {
            const { orderId } = commonParams;
            return EtrAPIClient.cart.carepackInfo({ orderId }).then(resp => {
                return resp;
            });
        },
        addCrossSell: (product, quantiy, eligibleSKU) => {
            return addToCart(product, quantiy, true, undefined, undefined, eligibleSKU, 'crossSell').then(resp => {
                dispatch(fetchCartView(false));
                return resp;
            });
        },
    };
};
