import { Helpers } from '../core/src/helpers';
import { EtrAPI } from '../core/src/etr-api-client';
import etrWCSCLient from '../core/src/etr-wcs-api-client';
import {
    setPreOrderParameters,
    PRE_ORDER_CHECKOUT_URL,
    parseCartResponse,
    getAddonProducts,
    getLinkedOrderItem,
    reduceAddOnQuantity,
    _addElgblSku,
    parseElgblSku,
} from './helpers';

// export const RECEIVE_CART = 'RECEIVE_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const RECENTLY_ADDED = 'RECENTLY_ADDED';
export const CLEAR_RECENTLY_ADDED = 'CLEAR_RECENTLY_ADDED';
export const REFRESH_CART = 'REFRESH_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const MANAGE_ORDER_LOCK = 'MANAGE_ORDER_LOCK';
export const TOGGLE_SERVICE_TIMEOUT = 'TOGGLE_SERVICE_TIMEOUT';
export const RECEIVE_UTILITY = 'RECEIVE_UTILITY';
export const RECEIVE_USER_IDLE = 'RECEIVE_USER_IDLE';
export const LAUNCH_CART_FLYOUT = 'LAUNCH_CART_FLYOUT';
export const CLOSE_CART_FLYOUT = 'CLOSE_CART_FLYOUT';
export const SET_CART_VIEW = 'SET_CART_VIEW';

export const handleClearRecentlyAdded = () => ({
    type: CLEAR_RECENTLY_ADDED,
});

//helper function to try to initialize pStoreID on client side
//this is to help avoid flicker on above the file components that are private store specific
//since we can't authenticate on server side we assume that if the pStoreID is in the url then they belong to that private store
export const getPrivateStoreID = () => {
    let pStoreID;
    try {
        pStoreID = Helpers.getSearch(window.location.search).pStoreID;
    } catch (e) {}
    return pStoreID;
};

/**
 * Check the cartInfo and returns the order item of id of any associated skus. This is used for matching item quantity in cart for associated skus
 * @param {*} cartItem
 * @param {*} items
 * @param {*} ordItmFld2
 */
const getAssociatedCartItemIds = (cartItem, cartInfo, matchHardwareQty = false) => {
    try {
        const { items = [], ordItmFld2 } = cartInfo || {};
        if (typeof ordItmFld2 === 'object' && Object.keys(ordItmFld2).length > 0) {
            const { subType, id, pNum, cId } = cartItem;
            let associatedIds;
            let associatedKey = `${pNum}${cId ? `|${cId}` : ''}`;
            if (subType === 'VCP' && matchHardwareQty) {
                //if item is eCarePack look through all ordItemFld2 and collect the sku ids
                const associatedSkus = Object.keys(ordItmFld2).reduce((r, sku) => {
                    const orderIds = ordItmFld2[sku];
                    if (id in orderIds) {
                        r[sku] = 1;
                    }
                    return r;
                }, {});
                //find the orderItemIds based on the sku
                associatedIds = items.reduce((r, item) => {
                    if (item.pNum in associatedSkus) {
                        r.push(item.id);
                    }
                    return r;
                }, []);
            } else {
                associatedIds = Object.keys(ordItmFld2[associatedKey] || {});
            }
            if (associatedIds && associatedIds.length > 0) {
                return [cartItem.id, ...associatedIds];
            }
        }
    } catch (e) {
        //catch exception to prevent cart updates from being blocked
    }
    return cartItem.id;
};

export const NULL_UTIL_STATE = {
    profileData: {
        personData: {
            userType: 'G',
            userTier: 'GS',
            pStoreID: getPrivateStoreID(),
            custSeg: 'CG_gs',
        },
        labels: {
            hpRewards: 'My HP Rewards',
            lblMyAccount: 'My Account',
            cutomerService: 'Customer Service',
            lblSignin: 'Sign in',
            lblRegister: 'Register',
            orderStatus: 'Order Status',
            lblSigninRegister: 'Sign in/Register',
            liveHelp: 'Live Help',
            lblMyOrders: 'My orders',
        },
        newsletterSubsribeUrl: `${process.env.BASENAME}/ManageSubscription?catalogId=10051&amp;langId=-1&amp;storeId=10151`,
        hpRewardsUrl: `${process.env.BASENAME}/LoadLoyaltyLandingPageCmd?catalogId=10051&amp;userTyp=G&amp;storeId=10151`,
        homeUrl: process.env.BASENAME,
        isccf: false,
    },
    callCenter: {},
    serviceTimeout: null,
    userIdle: false,
    cartData: {},
    updatedFromService: false,
};

export const receiveOrderLock = orderLock => ({
    type: MANAGE_ORDER_LOCK,
    orderLock,
});

export const receiveCart = (
    actionType,
    cartData = NULL_OBJECT,
    product,
    qty,
    addOns,
    eligibleSKU,
    xsellType,
    metricParams,
) => {
    return {
        type: actionType,
        cartData,
        product,
        eligibleSKU,
        xsellType,
        qty,
        addOns,
        metricParams,
    };
};
export const receiveUserIdle = userIdle => ({
    type: RECEIVE_USER_IDLE,
    userIdle,
});

export const receiveUtility = (utilityInfo, updatedFromService) => ({
    type: RECEIVE_UTILITY,
    utilityInfo,
    updatedFromService,
});

export const toggleServiceTimeout = toggle => {
    return {
        type: TOGGLE_SERVICE_TIMEOUT,
        toggle,
    };
};

export const launchCartFlyout = payload => ({
    type: LAUNCH_CART_FLYOUT,
    payload,
});

export const closeCartFlyout = () => ({
    type: CLOSE_CART_FLYOUT,
});

export const setUserIdle = userIdle => (dispatch, getState) => {
    const { userData } = getState();

    if (userData.userIdle !== userIdle) {
        return dispatch(receiveUserIdle(userIdle));
    }
};

export const setCartView = cartView => ({
    type: SET_CART_VIEW,
    cartView,
});

/**
 * Get detailed cart data for the cart page
 * @returns
 */
export const fetchCartView = calculate => (dispatch, getState) => {
    const { cartInfo } = getState();
    const { id } = cartInfo;

    return etrWCSCLient.cart.get({ orderId: id, calculate, HPRestFlow: 'Y' }).then(cartData => {
        if (cartData) {
            return dispatch(setCartView(cartData));
        }
    });
};

export const fetchProfileAndCart = () => dispatch => {
    return EtrAPI.services
        .fetchProfileAndCart()
        .then(resp => {
            let utilityInfo = resp.status == '200' ? resp.data : { ...NULL_UTIL_STATE };
            return dispatch(receiveUtility(utilityInfo, true));
        })
        .catch(e => {
            //if this fails dispatch the null state so we will at least track metrics
            // because page view in metrics middleware looks for this event
            // as UDL waits for user info to be received
            return dispatch(receiveUtility({ ...NULL_UTIL_STATE, userFetchFailed: true }));
        });
};

export const fetchCart =
    (actionType, product, qty, blockFlyout, addOns, eligibleSKU, xsellType, metricParams) => dispatch => {
        return EtrAPI.services.fetchCart().then(resp => {
            if (resp.status == '200') {
                let cartData = resp.data.cartData;
                return dispatch(
                    receiveCart(actionType, cartData, product, qty, addOns, eligibleSKU, xsellType, metricParams),
                );
            }
        });
    };

// this list of params keep growing, should we just pass the product object ?
export const updateCart = (cartItem, curQty) => (dispatch, getState) => {
    let { id, qty } = cartItem;
    const { cartInfo } = getState();
    const orderItemIds = getAssociatedCartItemIds(cartItem, cartInfo);
    if (curQty > 0) {
        return EtrAPI.cart.updateCartItem(orderItemIds, curQty).then(resp => {
            if (resp.status == '200') {
                let data = parseCartResponse(resp);
                if (data.errorMessage) {
                    alert(data.errorMessage);
                }
                dispatch(fetchCart(UPDATE_CART, cartItem, curQty));
            }
        });
    } else {
        return EtrAPI.cart.deleteCartItem(orderItemIds).then(resp => {
            if (resp.status == '200') {
                curQty = qty;
                let data = parseCartResponse(resp);
                if (data.errorMessage) {
                    alert(data.errorMessage);
                }
                dispatch(fetchCart(REMOVE_FROM_CART, cartItem, curQty));
            }
        });
    }
};

export const updateCartBySKU = (sku, curQty) => (dispatch, getState) => {
    const { cartInfo } = getState();
    const { items = [] } = cartInfo || {};
    const cartItem = items.find(i => i.pNum === sku);
    if (cartItem) {
        return dispatch(updateCart(cartItem, curQty));
    }
};

/**
 * Add multiple product to cart in a single call
 * @param  {[Array]}   products    [Array of string ids or product Objects]
 * @param  {[Array]}   qty         [Parallel array of quantity]
 * @param  {[Boolean]} blockFlyout [Block cart fly out after add to cart action]
 * @param  {[Object]}  options     [additional options to send with add to cart function]
 * @param  {[String]}  eligibleSKU [Base product sku for xsell products, needed only for xsell products]
 * @param  {[String]}  xsellType   [xsell modal type used when adding xsell products to cart, needed only for xsell products]
 * @return {[Promise]}
 */
export const addMultipleToCart =
    (products, qty, blockFlyout, options = {}, eligibleSKU, xsellType, ctaMetrics) =>
    dispatch => {
        // initialize eCarepack option
        options.eCarepack = [];

        let id = products.reduce((ids, p, i) => {
            if (typeof p === 'string') {
                ids.push(p);
            } else {
                ids.push(p.itemId || p.catEntryId || p.catentryId);
                if (Helpers.isECarepack(p)) {
                    options.eCarepack.push(_addElgblSku(options.baseSKU, i));
                }
            }
            return ids;
        }, []);

        return EtrAPI.cart.addToCart(id, qty, options).then(resp => {
            if (resp.status == '200') {
                let data = parseCartResponse(resp);
                if (data.errorMessage) {
                    alert(data.errorMessage);
                } else {
                    dispatch(
                        fetchCart(
                            ADD_TO_CART,
                            products,
                            (qty = 1),
                            blockFlyout,
                            null,
                            eligibleSKU,
                            xsellType,
                            ctaMetrics,
                        ),
                    );
                }
            }
        });
    };

/**
 * Add product to cart
 * @param  {[Object]}  product      [The primary product to be added to cart]
 * @param  {[Number]}  qty          [The quantity to add for the primary product]
 * @param  {[Boolean]} blockFlyout  [Boolean to block cart flyout after add to cart]
 * @param  {[Array]}   addOns       [Array of additional products to be added to cart, Format: [{ product: Object, qty: Number},]
 * @param  {[Object]}  options      [additional options, use for things like giftcards, xsell base product]
 * @param  {[String]}  eligibleSKU [Base product sku for xsell products, needed only for xsell products]
 * @param  {[String]}  xsellType   [xsell modal type used when adding xsell products to cart, needed only for xsell products]
 * @return {[Promise]}
 */

/*eligibleSKU and xsellType are optional params dedicated for xsell CTA tracking, they only apply to situations where a xsell product being added via a xsell module*/
export const addToCart =
    (product, qty = 1, blockFlyout, addOnProducts, options = {}, eligibleSKU, xsellType, metricParams) =>
    async (dispatch, getState) => {
        //extract local options
        const { disableRecentlyAdded, removeExistingAssociation, forceQty, ...restOptions } = options;
        options = restOptions;
        let id;
        const isESKU = Helpers.isECarepack(product);
        options.eCarepack = [];
        const { productData, cartInfo, siteConfig, userData = {} } = getState();
        let { productInfo = {} } = productData;
        const { checkoutUrl: hpsCheckoutUrl } = userData.profileData || {};
        const { preOrderSettings } = siteConfig;
        const { checkoutUrl: preOrderCheckoutUrl } = preOrderSettings || {};
        const { productPrices } = productInfo;
        const { items = [], ordItmFld2 = {} } = cartInfo;
        const priceObj = productPrices[product.sku];
        let removeAssociationItem;
        let followOnCarePack;
        const linkedOrderItem = getLinkedOrderItem(product, cartInfo);
        //checks if the primary product being added already exists in cart, this impacts how we include addOn care packs
        const primaryProductItem = items.find(i => i.pNum === product.sku);
        const primaryProductQuantity = qty;
        const primaryProductTotalQuantity = primaryProductItem && primaryProductItem.qty + primaryProductQuantity;

        setPreOrderParameters(product, priceObj, cartInfo, options);
        const addOns = getAddonProducts(product, addOnProducts, productData, linkedOrderItem);
        // check if eCarepack is added as main product
        //TODO: need to consolidate the usage of this base sku concept. Too many different variables for the same thing
        if (isESKU && (options.baseSKU || options.userInputBaseSKU || eligibleSKU) && product.Devdependency !== 'NO') {
            options.eCarepack.push(
                options.userInputBaseSKU
                    ? { elgblSku: options.userInputBaseSKU }
                    : _addElgblSku(options.baseSKU || eligibleSKU, null, options.serialNumber),
            );
            //update quantity to match eligible sku in cart
            const baseSkuID = options.userInputBaseSKU || options.baseSKU || eligibleSKU;
            const [targetSku, targetCartID] = (baseSkuID || '').split('|');
            const eligibleSkuCartItem = items.find(i => {
                //if cart ID included in baseSKU id use it, else use sku
                if (targetCartID) {
                    return i.cId === targetCartID;
                }
                return i.pNum === targetSku;
            });
            if (eligibleSkuCartItem && eligibleSkuCartItem.qty && !forceQty) {
                qty = eligibleSkuCartItem.qty;
            }
            //if option to remove existing assocation, set the cart item id to be removed
            if (removeExistingAssociation && targetSku in ordItmFld2) {
                const [cartItemId] = Object.keys(ordItmFld2[targetSku]);
                removeAssociationItem = items.find(({ id }) => id === cartItemId);
            }
        }

        if (addOns && addOns.constructor === Array && addOns.length > 0) {
            // reduce addons to parallel arrays of itemId and qty
            let { addOnId, addOnQty, addOnCarePacks, followOnCarePackFromAddOn } = reduceAddOnQuantity(
                product,
                addOns,
                primaryProductItem,
            );
            options.eCarepack = options.eCarepack.concat(addOnCarePacks);
            followOnCarePack = followOnCarePackFromAddOn;
            //append addon SKUs to array
            id = [product.itemId || product.catentryId, ...addOnId];
            qty = [qty, ...addOnQty];
        } else {
            id = product.itemId || product.catentryId || product.catEntryId;
        }

        if (linkedOrderItem) {
            //if there is an existing association and they are attempting to add a new one, remove the old one first
            if (
                removeExistingAssociation &&
                (options.eCarepack.find(cp => parseElgblSku(cp) === product.sku) || followOnCarePack)
            ) {
                await dispatch(updateCart(linkedOrderItem, 0));
            } else {
                //if product has an existing care pack association, update the quantity
                options.ecareOrdItem = linkedOrderItem.id;
                options.ecareQty = linkedOrderItem.qty + primaryProductQuantity;
            }
        }

        return EtrAPI.cart.addToCart(id, qty, options).then(resp => {
            if (resp.status == '200') {
                let data = parseCartResponse(resp);

                if (data.errorMessage) {
                    alert(data.errorMessage);
                    return Promise.reject();
                } else if (options.isPreOrder) {
                    //if adding pre-order product, send to special pre-order checkout page on success
                    document.location.href = Helpers.decodeHtml(
                        hpsCheckoutUrl || preOrderCheckoutUrl || PRE_ORDER_CHECKOUT_URL,
                    );
                } else {
                    const { ecareOrdItem, ecareQty } = data || {};
                    const [eCareOrderItemId] = ecareOrdItem || [];
                    const [eCareOrderQty] = ecareQty || [];
                    !disableRecentlyAdded &&
                        dispatch({
                            type: RECENTLY_ADDED,
                            product,
                            addToCartResponse: data,
                            options,
                        });
                    if (removeAssociationItem) {
                        return dispatch(updateCart(removeAssociationItem, 0));
                    }
                    if (eCareOrderItemId && eCareOrderQty) {
                        //if care pack association update cart quantity
                        return dispatch(updateCart({ id: eCareOrderItemId }, eCareOrderQty));
                    }
                    if (followOnCarePack) {
                        return dispatch(
                            addToCart(
                                followOnCarePack,
                                primaryProductTotalQuantity,
                                true,
                                [],
                                {
                                    disableRecentlyAdded: true,
                                    forceQty: true,
                                },
                                product.sku,
                            ),
                        );
                    }
                    return dispatch(
                        fetchCart(
                            ADD_TO_CART,
                            product,
                            (qty = 1),
                            blockFlyout,
                            addOns,
                            eligibleSKU,
                            xsellType,
                            metricParams,
                        ),
                    );
                }
            }
        });
    };

/**
 * Add cto preconfig to cart
 * @param  {[Object]}  product      [The primary product to be added to cart]
 * @param  {[Number]}  qty          [The quantity to add for the primary product]
 * @param  {[Boolean]} blockFlyout  [Boolean to block cart flyout after add to cart]
 * @return {[Promise]}
 */
export const addCTOToCart =
    (product, qty, blockFlyout = true, followRedirect = true, onAdd = () => {}, onComplete = err => {}) =>
    (dispatch, getState) => {
        const itemId = product.itemId || product.catentryId || product.catEntryId;
        const { productData } = getState();
        const { productInfo, addOns } = productData;
        const { productPrices } = productInfo;
        const priceData = productPrices[product.sku] || {};
        const addOnProducts = addOns[product.sku] || [];
        const { product: eCarePack } =
            addOnProducts.find(p => {
                return p?.product?.eCarePack;
            }) || {};
        const { ctoLink } = priceData;
        onAdd();
        return EtrAPI.cto.addPreConfig(itemId).then(resp => {
            if (!resp) {
                onComplete('No response');
                return Promise.reject();
            }
            return dispatch(fetchCart(ADD_TO_CART, product, (qty = 1), blockFlyout))
                .then(() => {
                    onComplete();
                    if (!followRedirect) {
                        return;
                    }
                    if (resp.redirecturl != undefined && resp.redirecturl == 'AccessoryAttachView') {
                        document.location.href = `${process.env.BASENAME}/${
                            resp.redirecturl
                        }?shipDate=${encodeURIComponent(
                            resp.shipDate,
                        )}&selectedRecommConfig=recConfig1&catEntryId=${itemId}&storeId=10151${
                            eCarePack ? `&cpCatEntryId=${eCarePack.catentryId}` : ''
                        }`;
                    } else if (resp.redirecturl != undefined && resp.redirecturl == 'AjaxOrderItemDisplayView') {
                        document.location.href = `${process.env.BASENAME}/${resp.redirecturl}?storeId=10151&catalogId=10051&langId=-1`;
                    } else {
                        let params = {};
                        if (typeof a2Cdata.dl != 'undefined' && a2Cdata.dl === 'true') {
                            params.dl = 'true';
                        }
                        if (Helpers.isValidHpUrl(ctoLink)) {
                            document.location.href = ctoLink;
                        }
                    }
                })
                .catch(e => {
                    onComplete(e);
                    if (!followRedirect) {
                        return;
                    }
                    if (ctoLink) {
                        document.location.href = ctoLink;
                    }
                });
        });
    };

export const manageOrderLock = ccAction => dispatch => {
    return EtrAPI.callCenter.manageOrderLock(ccAction).then(resp => {
        try {
            resp = resp.data.replace('/*', '').replace('*/', '').trim();
            resp = JSON.parse(resp);
            if (resp && resp.isSuccess === 'Y') {
                resp.message = 'Cart is now ' + (resp.lockAction === 'A' ? 'locked' : 'unlocked');
            } else {
                //standardize the response in message
                resp.message = resp.errorMessage;
                resp.errorMessageKey = resp.errorMessageKey || 'EXCEPTION';
            }

            dispatch(receiveOrderLock(resp));
        } catch (e) {
            dispatch(
                receiveOrderLock({
                    message: 'Failed to perform the requested operation. Retry.',
                    errorMessageKey: 'EXCEPTION',
                }),
            );
        }
    });
};
