import React from 'react';
import { Redirect } from 'react-router-dom';

import { Helpers } from './core/src/helpers';
import ConfigureContainer from './configure';
import ConfigureAttachContainer from './configure-attach';
import CartPage from './cart';
import CLPContainer from './category-landing-page';
import MainContainer from './main/components/main-container';
import ErrorRedirect from './main/components/error-redirect';
import PDP from './pdp';
import ProductReviewsPage from './product-reviews-page';
import MLPContainer from './mlp';
import MDPContainer from './mdp';
import HomeContainer from './home';
import MMDContainer from './pdp/components/mmd';
import BlogListContainer from './blog/components/blog-list-container';
import BlogContainer from './blog/blog-container';
import DLPContainer from './dlp';
import PLPContainer from './plp';
import VWAContainer from './vwa';
import CompareView from './compare/components/compare-view';
import ReorderContainer from './reorder/components/reorder-container';
import SearchContainer from './search';
import SubBrand from './sub-brand';
import { withHeaderFooter } from './page/components/header-footer';
import { lazyScrollPosition } from './hooks/useLazyScroll';
import PagePlaceholder from './page/components/page-placeholder';
import useTestFlags from './abtest/useTestFlags';

const { memoize } = Helpers;

/**
 * Strips out ETR preview path and redirects
 * @param {*} props
 * @returns
 */
const PreviewRedirect = props => {
    const { match, location } = props;
    const { params, url } = match;
    const { search } = location;
    const { previewPath } = params;
    const to = {
        pathname: url.replace(previewPath, ''),
        search: search,
    };
    return <Redirect to={to} />;
};

/**
 * Catches unsupported routes and redirects them to the ETR page
 * @param {*} props
 * @returns
 */
const ETRRedirect = props => {
    const { match, location } = props;
    const { url } = match;
    const { search } = location;
    const { headlessCart } = useTestFlags(['headlessCart']);
    if (headlessCart && url.includes('OrderCalculate')) {
        return <Redirect to="/cart" />;
    }
    if (typeof window !== 'undefined') {
        const origin =
            window.location.origin.indexOf('localhost') > -1 ? 'https://uat3live.store.hp.com' : window.location.origin;
        window.location.href = `${origin}${url}${search}`;
        return <PagePlaceholder />;
    } else {
        //For SSR. This should never happen. Akamai proxy rules should never point to our origin for non supported routes
        return <ErrorRedirect {...props} />;
    }
};

//flag to avoid resetting scroll on first SSR load
let skipInitial = true;
let lastScrollPosition = {};
/**
 * Optimization HOC, for some reason with SSR on initial load the location and match update several times
 * @param  {[type]} WrappedComponent [description]
 * @param  {[type]} resetScroll      [Option to reset scroll and rout change]
 * @return {[type]}                  [description]
 */
function shouldRouteRerender(WrappedComponent, resetScroll, shouldResetScroll) {
    return class extends React.Component {
        checkPDPPath = pathname => {
            //if landing on PDP lock the lastScrollPosition in-case user hits back button.
            //This is to allow the user to go back to the same position they were at before after viewing a PDP
            if (pathname.indexOf('/pdp/') > -1) {
                lastScrollPosition = Helpers.getLastScrollPosition();
            }
        };

        resetScrollPosition(checkForLoader) {
            const { location } = this.props;
            //disable PDP scroll reset
            const enablePDPScrollReset = false;
            const { pathname } = location;
            //if hard loading then check session storage for last scroll position
            const scrollPos = skipInitial ? Helpers.getLastScrollPosition() : lastScrollPosition;
            const isLoading = checkForLoader && document.getElementById('pageloader');
            //if we are coming from a PDP page and going to the same page, reset scroll to the last position
            if (
                enablePDPScrollReset &&
                !isLoading &&
                document.referrer.indexOf('/pdp/') > -1 &&
                scrollPos.path === pathname
            ) {
                setTimeout(() => {
                    const lastVisitSku = sessionStorage.getItem('lastVisitSku');
                    const target = lastVisitSku ? document.querySelector(`[data-sku='${lastVisitSku}']`) : null;
                    //if target sku is on the page scroll and last scroll position is much farther away scroll to it, else scroll to last know position
                    if (target && scrollPos.position - target.offsetTop > 800) {
                        target.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        lazyScrollPosition(scrollPos.position, {
                            maxAttempts: 50,
                            patchDelay: 500,
                            threshold: 0,
                            backoff: false,
                        });
                    }
                }, 500);
            } else if (resetScroll) {
                //if page is loading reset scroll position, then check again for previous scroll position
                isLoading && setTimeout(() => this.resetScrollPosition(isLoading), 100);
                setTimeout(() => {
                    !skipInitial && window.scrollTo(0, 0);
                    skipInitial = false;
                }, 100);
            }
        }

        componentDidMount() {
            const { location } = this.props;
            const { pathname } = location;

            this.checkPDPPath(pathname);
            setTimeout(() => {
                this.resetScrollPosition(true);
            }, 100);
        }

        componentWillUnmount() {
            const { location } = this.props;
            const { pathname } = location;
            //when unmounting save scroll position
            Helpers.saveLastScrollPosition(pathname);
        }

        shouldComponentUpdate(nextProps, nextState) {
            let { location: nextLocation = {}, match: nextMatch = {} } = nextProps;
            let { location = {}, match = {} } = this.props;
            //only update when the URL changes
            return (
                `${location.pathnam}${location.search}${location.hash}` !==
                    `${nextLocation.pathnam}${nextLocation.search}${nextLocation.hash}` || match.url !== nextMatch.url
            );
        }

        componentDidUpdate(prevProps) {
            //TODO: This never only gets hit when template is the same. It used to not unmount when switching templates
            const { match, location } = this.props;
            const { hash: matchHash } = location;
            const { match: prevMatch } = prevProps;
            const prevMatchHash = prevProps.location.hash;
            //for now just reset if the slug changes
            if (resetScroll && match.url !== prevMatch.url) {
                try {
                    const setNewScroll = shouldResetScroll
                        ? shouldResetScroll({ ...prevMatch, hash: prevMatchHash }, { ...match, hash: matchHash })
                        : match.url !== prevMatch.url;
                    if (setNewScroll) {
                        setTimeout(() => {
                            window.scrollTo(0, 0);
                        }, 100);
                    }
                } catch (e) {}
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default memoize((basename = process.env.BASENAME, pathOnly = false, enableNewRoutes = false) => {
    basename = basename || process.env.BASENAME;
    const baseNamePattern = `/:basename(${basename.replace(/^\//, '')}|app)`;
    let routes = [
        {
            ...HomeContainer,
            path: `${baseNamePattern}`,
            exact: true,
            resetScroll: true,
        },
        {
            ...CLPContainer,
            path: `${baseNamePattern}/:dir(cat)/:slug`,
            exact: true,
            resetScroll: true,
        },
        {
            ...MDPContainer,
            path: `${baseNamePattern}/:dir(mdp)/:slug/:slugTwo?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...MLPContainer,
            path: `${baseNamePattern}/:dir(mlp)/:slug/:slugTwo?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...ConfigureContainer,
            path: `${baseNamePattern}/:dir(configure|ConfigureView)/:slug?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...ConfigureAttachContainer,
            path: `${baseNamePattern}/:dir(configureattach|AccessoryAttachView)`,
            exact: true,
            resetScroll: true,
        },
        {
            ...SearchContainer,
            path: `${baseNamePattern}/:dir(sitesearch)`,
            exact: true,
            resetScroll: true,
        },
        {
            component: PreviewRedirect,
            path: `:previewPath(/webapp/wcs/preview/servlet)/*`,
            exact: true,
            resetScroll: false,
        },
        /*leave route to handle redirects of legacy template*/
        {
            ...PLPContainer,
            path: `${baseNamePattern}/:dir(plp|filters)/:slug/:filters?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...VWAContainer,
            path: `${baseNamePattern}/:dir(vwa)/:slug/:filters?`,
            exact: true,
            resetScroll: true,
            shouldResetScroll: (match, nextMatch) => {
                //only reset scroll if it's a different template or a different VWA page
                const { dir, slug } = match;
                const { dir: nextDir, slug: nextSlug } = nextMatch;
                return dir !== nextDir || slug !== nextSlug || match.hash !== nextMatch.hash;
            },
        },
        {
            ...DLPContainer,
            path: `${baseNamePattern}/:dir(dlp)/:slug`,
            exact: true,
            resetScroll: true,
        },
        {
            ...PDP,
            path: `${baseNamePattern}/:dir(pdp)/:slug/:filters?`,
            exact: true,
            //disabled scroll reset for PDP, this is handle by the PDP template itself because of Shadow CTO
            resetScroll: false,
        },
        {
            ...ProductReviewsPage,
            path: `${baseNamePattern}/:dir(reviews)/:slug/:filters?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...MMDContainer,
            path: `${baseNamePattern}/:dir(mmd)/:sku?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...BlogListContainer,
            path: `${baseNamePattern}/:dir(tech-takes)/article-filters/:filters?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...BlogListContainer,
            path: `${baseNamePattern}/:dir(tech-takes)`,
            exact: true,
            resetScroll: true,
        },
        {
            ...BlogContainer,
            path: `${baseNamePattern}/:dir(tech-takes)/:slug/:filters?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...ReorderContainer,
            path: `${baseNamePattern}/reorder`,
            exact: false,
            resetScroll: true,
        },
        {
            ...MainContainer,
            path: `${baseNamePattern}/:dir(slp)/:slug/:filters?`,
            exact: true,
            resetScroll: true,
            shouldResetScroll: (match, nextMatch) => {
                //only reset scroll if it's a different tempalte or a different VWA page
                const { params } = match;
                const { params: nextParams } = nextMatch;
                const { dir, slug } = params;
                const { dir: nextDir, slug: nextSlug } = nextParams;
                return dir !== nextDir || slug !== nextSlug;
            },
        },
        {
            ...SubBrand,
            path: `${baseNamePattern}/:dir(printer-supplies)/:slug(ink-toner)/:slugTwo?`,
            exact: true,
            resetScroll: true,
        },
        {
            component: CompareView,
            path: `${baseNamePattern}/compare/:type?/:id?`,
            exact: true,
            resetScroll: true,
        },
        {
            ...CartPage,
            path: `${baseNamePattern}/cart`,
            exact: true,
            resetScroll: true,
        },
        {
            component: ETRRedirect,
            path: `${baseNamePattern}/*`,
            exact: true,
        },
        {
            component: ErrorRedirect,
        },
    ];

    if (pathOnly) {
        return routes.map(({ path, exact }) => {
            return { path, exact };
        });
    }

    //TEMP: Fix issue with multiple re-renders because of location change on inital load for SSR
    return routes.map(r => {
        //TODO: remove withHeaderFooter once all routes have been migrated to use the Page component
        r.component = shouldRouteRerender(withHeaderFooter(r.component), r.resetScroll, r.shouldResetScroll);
        return r;
    });
});
